//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";


.crego-app {
    padding: 1rem 2.5rem;
}

.spin {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

@include media-breakpoint-up(lg) {
    .drawer.drawer-on {
        width: 450px;
    }

    .drawer.drawer-on.drawer-md {
        width: 550px;
    }

    .drawer.drawer-on.drawer-lg {
        width: 700px;
    }
}


@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.form-check {
    &.form-check-transparent {
        .form-check-input {
            &:checked {
                background-color: transparent !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%2317c653%27/%3e%3c/svg%3e");
            }
        }
    }

    &.form-check-solid {
        .form-check-input {
            &.form-check-circle {
                border-radius: 50%;
            }
        }
    }
}


.slider {
    position: relative;
    width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
}

.slider__range {
    background-color: #019ef7;
    z-index: 2;

    &.slider__range-success {
        background: #50cd89;
    }

    &.slider__range-warning {
        background: #ffc700;
    }

    &.slider__range-danger {
        background: #f1416c;
    }
}

.slider__left-value,
.slider__right-value {
    font-size: 12px;
    margin-top: 10px;
}

.slider__left-value {
    left: 6px;
}

.slider__right-value {
    right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
}

.thumb--zindex-3 {
    z-index: 3;
}

.thumb--zindex-4 {
    z-index: 4;
}

.thumb--zindex-5 {
    z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}


.input-group {
    .react-datepicker-wrapper:first-child {
        .react-datepicker__input-container {
            .form-control {
                border-radius: 0.425rem 0 0 0.425rem;
            }
        }
    }

    .react-datepicker-wrapper:last-child {
        .react-datepicker__input-container {
            .form-control {
                border-radius: 0 0.425rem 0.425rem 0;
            }
        }
    }

    .react-datepicker-wrapper {
        flex: 1;

        .react-datepicker__input-container {
            .form-control {
                border-radius: 0;
            }
        }
    }


    &.input-group-labels {
        display: flex;

        .form-label {
            flex: 1;
        }
    }

    .css-b62m3t-container {
        margin-right: 0 !important;

        .css-13cymwt-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-right: 0 !important;
        }
    }


}

.collapsible {
    .card-summary {
        display: none;
    }

    &.collapsed {
        .card-summary {
            display: block;
        }
    }
}

.max-width-100 {
    max-width: 100px;
}

.max-width-200 {
    max-width: 200px;
}

.max-width-300 {
    max-width: 300px;
}

.max-width-500 {
    max-width: 500px;
}

.avatar {
    width: 70px;
    height: 70px;
    border: 1px solid #78829D;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
    }
}

.popover {
    --bs-popover-max-width: 500px !important;
}

.minipanel {
    position: fixed;
    width: 300px;
    right: 305px;
    top: 30%;
    background: #fff;

    &.minipanel-md {
        width: 400px;
    }

    &.minipanel-lg {
        width: 500px;
    }
}

.panel {
    position: fixed !important;
    background: #fff;
    width: 300px;
    right: 10px;
    top: 65px;
    bottom: 10px;
    overflow-y: scroll;

    &.panel-md {
        width: 400px;
    }

    &.panel-lg {
        width: 500px;
    }

    &.panel-xl {
        width: 700px;
    }

    .dropdown-menu {
        max-height: 300px;
        overflow-y: scroll;
    }

    .panel__section {
        border-bottom: 1px solid var(--bs-gray-200);
        position: relative;

        .minipanel {
            position: absolute;
            top: 0;
        }
    }

    .row {
        .mb-5.flex-column {
            margin-bottom: 0 !important;
        }
    }

    .col-form-label {
        font-size: 0.85rem;
    }
}

.navpills {
    background: #dbdee4;
    border-radius: 0.675rem;
    margin: 0;
    display: flex;

    li {
        flex: 1;
        list-style: none;
    }

    .navpills__item {
        margin: 3px;

        .navpills__item__link {
            cursor: pointer;
            display: block;
            text-align: center;
            padding: 2px 0;
            border-radius: 0.475rem;

            &.navpills__item__link-active {
                background: #fff;
            }
        }
    }
}

.form-select-xs {
    padding: 0 0.5rem;
    height: 2rem;
    font-size: 0.85rem;
    background-size: 6px;
    background-position: right 0.4rem center;
}

.form-select-attached,
.form-control-attached {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-xs {
    .input-group-text {
        padding: 0 0.5rem;
        height: 2rem;
        font-size: 0.85rem;
    }

    .form-control {
        padding: 0 0.5rem;
        height: 2rem;
        font-size: 0.85rem;
    }

    .form-select {
        padding: 0 0.5rem;
        height: 2rem;
        font-size: 0.85rem;
        background-size: 6px;
        background-position: right 0.4rem center;
    }

    .btn {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
        height: 2rem;
        line-height: 2rem;
        font-size: 0.85rem;

        &.btn-icon {
            width: 2rem;
            height: 2rem;
        }
    }
}

.form-control-xs {
    padding: 0 0.5rem !important;
    height: 2rem !important;
    min-height: 2rem !important;
    font-size: 0.85rem !important;
}

.super-input-group {
    >.input-group-wrapper:first-child:not(:only-child) .input-group {

        .form-select,
        .form-control {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    >.input-group-wrapper:last-child:not(:only-child) .input-group {

        .form-select,
        .form-control {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
        }
    }

    >.input-group-wrapper:not(:first-child):not(:last-child):not(:only-child) .input-group {

        .form-select,
        .form-control {
            border-top: 0;
            border-radius: 0;
        }
    }
}

.crego_react_select__control-sm {
    .crego_react_select__control {
        min-height: 2rem !important;
        height: 2rem;
    }

    .crego_react_select__value-container {
        padding: 0;
    }

    .crego_react_select__input-container {
        padding: 0;
    }

    .crego_react_select__single-value {
        margin: 0 0 0 0.5rem !important;
    }

    .crego_react_select__indicator {
        padding: 0;
    }
}

.suggestions-wrapper {
    position: relative;
}

.suggestions {
    z-index: 999;
    background-color: #f9f9f9;
    border-radius: 0.675rem;
    max-height: 300px;
    overflow: scroll;
    list-style: none;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 5px);

    .suggestions__item {
        display: block;
        padding: 5px;
        font-size: 11px;
        cursor: pointer;

        &:hover,
        &.suggestions__item-highlighted {
            background-color: #b4d7ff;
        }
    }
}

.w-90 {
    width: 90%;
}

.input-group>.input-group-prepend {
    flex: 0 0 30%;
}

.input-group>.input-group-prepend-sm {
    flex: 0 0 20%;
}

.input-group>.input-group-prepend-xs {
    flex: 0 0 13%;
}

.react-flow__attribution a {
    display: none !important;
}

.grid-table {
    .col {
        padding: 0.5rem;
        border: 1px solid #dee2e6; // Bootstrap's table border color
        background-color: #fff; // Bootstrap's default table background
    }

    .col:nth-child(-n + 4) {
        background-color: #f8f9fa; // Light gray header background
        border-bottom: 2px solid #dee2e6;
    }

    .col:nth-child(4n) {
        text-overflow: ellipsis; // Adjust long text
        white-space: nowrap;
        overflow: hidden;
    }

    // Optionally add hover effects
    .col:hover {
        background-color: #f1f1f1;
    }
}


.custom-accordion-header {
    .accordion-button {
        padding: 10px;
        padding-right: 20px;
    }
}

.btn-xs {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    height: 2rem !important;
    line-height: 2rem !important;
    font-size: 0.85rem !important;

    &.btn-icon {
        width: 2rem !important;
        height: 2rem !important;
    }
}

.queryBuilder-compact {
    .ruleGroup {
        .ruleGroup-body {
            .ruleGroup {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .ruleGroup-body {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }
        }
    }
}